import {
  FinanceNotificationCode,
  IQuoteDto,
  IQuoteError
} from '~/types/finance/quote'

type FinanceNotificationType = 'budget' | 'term' | 'mileage'

const notificationsMap: Record<
  FinanceNotificationType,
  FinanceNotificationCode[]
> = {
  budget: [
    '24',
    '25',
    '38',
    '49',
    '50',
    '46',
    '47',
    '51',
    '52',
    '7',
    '8',
    '21',
    '42'
  ],
  term: ['1', '3', '2', '5', '4'],
  mileage: ['13', '36', '6']
}

export const useFinanceNotifications = (
  quote: Ref<IQuoteDto | IQuoteError | undefined>
) => {
  const notifications = computed(() => {
    if (!quote.value) return []

    if (isQuote(quote.value)) {
      return quote.value.Notifications || []
    }

    return []
  })

  const hasFinanceNotifications = computed(() => {
    return notifications.value.length > 0
  })

  // Budget
  const budgetNotifications = computed(() => {
    return notifications.value
      .filter(({ Code }) => notificationsMap.budget.includes(Code))
      .map(({ Message }) => Message)
  })

  // Term
  const termNotifications = computed(() => {
    return notifications.value
      .filter(({ Code }) => notificationsMap.term.includes(Code))
      .map(({ Message }) => Message)
  })

  // Mileage
  const mileageNotifications = computed(() => {
    return notifications.value
      .filter(({ Code }) => notificationsMap.mileage.includes(Code))
      .map(({ Message }) => Message)
  })

  // All
  const allNotifications = computed(() => {
    return notifications.value.map(({ Message }) => Message)
  })

  return {
    notifications,
    hasFinanceNotifications,
    budgetNotifications,
    termNotifications,
    mileageNotifications,
    allNotifications
  }
}
