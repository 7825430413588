<template>
  <div v-if="notifications.length > 0">
    <p
      v-for="message in formattedMessages"
      :key="message[0]"
      class="financeNotification text-sm leading-5 text-gray-500"
    >
      <template v-if="message.length === 3">
        <span>{{ message[0] }}</span>
        <span class="text bold text-gray-700">{{ message[1] }}</span>
        <span>{{ message[2] }}</span>
      </template>
      <template v-else>
        <span>{{ message[0] }}</span>
      </template>
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FinanceNotifications',

  props: {
    notifications: {
      type: Array as PropType<string[]>,
      required: true
    }
  },

  setup(props) {
    const regex = /(?<=adjusted to )(.*)(?=[\r]|(?<=\d)|(?<=months))/
    const formattedMessages = computed<string[][]>(() => {
      return props.notifications.map((_message: any) => {
        const message =
          typeof _message === 'object' ? _message.Message : _message

        const match = message.match(regex)

        if (match) {
          const value = match[0]
          // Splitting the string at the point where the price starts
          const parts = message.split(value)
          const messageBeforeValue = parts[0]
          const messageAfterValue = parts[1]

          return [messageBeforeValue, value, messageAfterValue]
        }

        return [message]
      })
    })

    return {
      formattedMessages
    }
  }
}
</script>

<style lang="scss" scoped>
.financeNotification {
  color: $grey--dark;
  margin-bottom: rem(16);

  & + & {
    margin-top: rem(-8);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
